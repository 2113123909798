

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import {api_url} from "@/ayar";
import TableBaseComponent from "@/components/backoffice/table/TableBaseComponent.vue";
import {onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import { l }           from '@/core/helpers/lang';

export default ({
  name: "BayiAdaylarim",
  components: {
    TableBaseComponent
  },
  data: () => {
    return {
      url: api_url + 'myEnrollers',
      columns: [
        {
          title: `<div class="form-check form-check-sm form-check-custom form-check-solid me-3 ms-2">
                            <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                        </div>`,
          class: 'w-10px pe-2 ',
          settings: {
            data: 'sponsor_id',
            orderable: false,
            searchable: false
          }
        },
        {
          title: l('admin:genel:sponsor_id'),
          settings: {
            data: 'sponsor_id',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:Adı Soyadı'),
          settings: {
            data: 'name',
            orderable: true
          }
        },
        {
          title: l('admin:genel:il'),
          settings: {
            data: 'city',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:GSM'),
          settings: {
            data: 'phone',
            orderable: true
          }
        },
      ],
      columnDefs: [{
        "render": function (data, type, row) {
          return `<div class="form-check form-check-sm form-check-custom form-check-solid ms-2">
                                <input
                                class="form-check-input widget-13-check"
                                type="checkbox"
                                value="${row['id']}"/>
                            </div>`;
        },
        "targets": 0,
      },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";
          },
          "targets": 1,
          className: 'text-right align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + row.name+' '+row.surname + "</div>";
          },
          "targets": 2,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + (row.city ?? '' ) + "</div>";
          },
          "targets": 3,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";
          },
          "targets": 4,
          className: 'text-right align-bottom'
        },
      ],

    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle(l('admin:transfer:Bayi Adaylarım'));
    });
    return{l}
  }
});
